//load host experiences in a drawer

import Drawer from "../common/Drawer";
import { initSwiper } from "../common/init-swiper";
import { ajax } from "../utils";

class LoadHostExperiences {
  constructor(){
    this.drawer = document.querySelector('.load-host-experiences-drawer');
    if(this.drawer){
      this.init();
    }
  }

  init(){
    window.addEventListener('click', (e => {
      let button = e.target;
      if(e.target.classList.contains('load-host-experiences') || e.target.closest('.load-host-experiences')){
        if(e.target.closest('.load-host-experiences')){
          button = e.target.closest('.load-host-experiences');
        }

        this.loadExperiences(button.dataset.hostId, button.dataset.url);
      }
    }));
  }

  loadExperiences(hostId, url){
    //in case it is already loaded in the drawer, do nothing
    if(hostId === this.drawer.dataset.currentHostId){
      return;
    }

    this.drawer.innerHTML = '';
    this.drawer.classList.add('loading-spinner');

     //trigger an ajax request to get the experiences from this host inside the drawer
     ajax(url + '?host_id=' + hostId, (html) => {
      this.drawer.innerHTML = html;

      //init swiper for the photos
      initSwiper(this.drawer);

      Drawer.init(() => {
        //bind the close button
        // //TODO > add this directly to Drawer class
        const closeDrawer = this.drawer.querySelector('.closeDrawer');
        if(closeDrawer){
          closeDrawer.addEventListener('click', () => {
            Drawer.closeDrawer(this.drawer.parentElement);
          });
        }
      });

      this.drawer.classList.remove('loading-spinner');
    }, 'GET', {}, {}, true, false);

    this.drawer.dataset.currentHostId = hostId;
  }
}

export default LoadHostExperiences;
